<template>
  <v-container>
    <div>
      <v-card
        v-if="documentData"
        class="pa-2"
      >
        <v-card-title class="subheading font-weight-bold info--text">
          <span>{{ documentData.title }}</span>
          <v-spacer></v-spacer>
          <document-menu
            v-if="canHandleResource"
            @editDocument="isShow.edit_doc=true"
            @deleteDocument="isShow.del_doc=true"
          />
        </v-card-title>
        <v-card-subtitle
          class="d-flex"
        >
          <span>{{ documentData.description }}</span>
          <v-spacer />
          <v-btn
            class="mt-3"
            link
            :to="{name:'pluginProcedureView',params:{id:procedureData.ext_id}}"
          >
            TORNA ALLA LISTA DOCUMENTI
          </v-btn>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
          <div
            class="d-flex justify-end"
          >
            <v-btn
              v-if="canHandleResource"
              :color="btnColor"
              :dark="btnDark"
              @click="isShow.upload = true"
            >
              <v-icon>
                mdi-upload
              </v-icon>
              CARICA FILE
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="documentData.documentFiles_count > 0"
              :color="btnColor"
              :dark="btnDark"
              :loading="loadingAll"
              @click="downloadAllWithAxios(documentData.title+'.zip')"
            >
              <v-icon>
                mdi-download
              </v-icon>
              Scarica intero documento
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <div
        v-if="finalDocumentFiles"
        class="mt-3"
      >
        <v-row
          v-for="(user, index) in finalDocumentFiles"
          :key="index"
        >
          <v-col
            cols="12"
          >
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="1"
                  >
                    <procedure-users-avatar :user="user.user" />
                  </v-col>
                  <v-col
                    cols="5"
                    align-self="center"
                  >
                    <h3>{{ user.user.fullName }}</h3>
                    <h4 v-if="user.user.company">
                      {{ user.user.company.name }}
                    </h4>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            v-for="(file,idx) in user.files"
            :key="idx"
            cols="2"
          >
            <file-card
              is-plugin-version
              :can-handle-file="canHandleResource"
              :procedure-id="procedureData.ext_id"
              :file-obj="file"
              :download-url="downloadUrl(file.id)"
              :upload-enabled="uploadEnabled(documentData)"
              @reloadData="reloadData"
            />
          </v-col>
        </v-row>
      </div>

      <upload-file
        is-plugin-version
        :procedure-id="procedureData.ext_id"
        :is-show="isShow.upload"
        :upload-url="upload_url"
        @close="isShow.upload=false"
        @reload-data="reloadData"
      />
      <edit-document
        is-plugin-version
        :is-show="isShow.edit_doc"
        :document-data="documentData"
        @close="isShow.edit_doc=false"
        @reload-data="reloadData"
      />
      <delete-document
        is-plugin-version
        :is-show="isShow.del_doc"
        :document-data="documentData"
        @close="isShow.del_doc=false"
        @reload-data="reloadData"
      />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import ProcedureUsersAvatar from '@/components/procedure/procedure-users-avatar.vue'
import UploadFile from '@/components/modals/upload-file.vue'
import EditDocument from '@/components/modals/edit-document.vue'
import DeleteDocument from '@/components/modals/delete-document.vue'
import DocumentMenu from '@/components/menus/document-menu.vue'
import FileCard from '@/components/cards/file-card.vue'

export default {
  name: 'PluginProcedureDocumentsView',
  components: {
    ProcedureUsersAvatar,
    UploadFile,
    EditDocument,
    DeleteDocument,
    DocumentMenu,
    FileCard,
  },
  data() {
    return {
      isShow: {
        del_file: false,
        upload: false,
        edit_doc: false,
        del_doc: false,
      },
      upload_url: '',
      procedureData: {},
      documentData: {},
      documentUsers: {},
      documentCompanies: {},
      documentFiles: {},
      finalDocumentFiles: {},
      del_file_obj: {},
      loading: {},
      loadingAll: false,
      isTheCreator: false,
    }
  },
  computed: {
    ...mapGetters(['token', 'loggedUser', 'procedureList', 'partnerSettings']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
    isOwner() {
      return this.loggedUser.company_role === 'owner'
    },
    isAdmin() {
      return this.loggedUser.company_role === 'admin'
    },
    canHandleResource() {
      return true
      //return this.isOwner || this.isAdmin || this.isTheCreator
    },
  },
  watch: {
    procedureList: {
      handler() {
        this.getData()
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    async reloadData() {
      const procedureId = parseInt(this.$route.params.id, 10)
      await this.$store.dispatch('getPluginProcedure', procedureId)
      await this.getData()
      await this.$store.dispatch('setProductPercentages')
    },
    async getData() {
      const procedureId = parseInt(this.$route.params.id, 10)
      this.procedureData = await this.procedureList.find(item => item.ext_id === procedureId)
      this.isTheCreator = this.checkIfIsCreator()

      // prendo gli utenti dei documenti
      this.documentUsers = this.procedureData.users
      this.documentUsers = this.documentUsers.filter(el => el !== null)

      const documentId = parseInt(this.$route.params.did, 10)
      this.documentData = await this.procedureData.documents.find(item => item.id === documentId)
      this.upload_url = `${this.$apiUrl}ext/procedures/${this.procedureData.ext_id}/documents/${documentId}`

      this.documentFiles = this.documentData.document_files.map(el => el)

      const documentFilesPro = {}
      const documentFilesUser = {}

      this.documentFiles.map(el => {
        if (el.document_creator === 'professional') {
          if (!(el.user_id in documentFilesPro)) {
            documentFilesPro[el.user_id] = {
              user: el.user,
              files: {},
            }
          }

          documentFilesPro[el.user_id].files[el.id] = el
        } else {
          if (!(el.user_id in documentFilesUser)) {
            documentFilesUser[el.user_id] = {
              user: el.user,
              files: {},
            }
          }

          documentFilesUser[el.user_id].files[el.id] = el
        }

        return false
      })

      this.finalDocumentFiles = { ...documentFilesPro, ...documentFilesUser }
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    downloadWithAxios(fid, title, idx) {
      const url = `${this.$apiUrl}ext/procedures/${this.$route.params.id}/documents/${this.$route.params.did}/files/${fid}`
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(response => {
          this.forceFileDownload(response, title)
          this.setLoading(idx)
        })
        .catch(error => {
          switch (error.response.status) {
            case 403:
              this.$store.dispatch('set_alert', { msg: 'Non hai i permessi necessari per scaricare il file.', type: 'error' })
              break
            default:
              this.$store.dispatch('set_alert', { msg: 'Errore nel download, si prega di riprovare.', type: 'error' })
              break
          }
          this.setLoading(idx)
        })
    },
    downloadAllWithAxios(title) {
      this.loadingAll = true
      const url = `${this.$apiUrl}ext/procedures/${this.$route.params.id}/documents/${this.$route.params.did}/download/all`
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(response => {
          this.forceFileDownload(response, title)
          this.loadingAll = false
        })
        .catch(error => {
          switch (error.response.status) {
            case 403:
              this.$store.dispatch('set_alert', { msg: 'Non hai i permessi necessari per scaricare i files.', type: 'error' })
              break
            default:
              this.$store.dispatch('set_alert', { msg: 'Errore nel download, si prega di riprovare.', type: 'error' })
              break
          }
          this.loadingAll = false
        })
    },
    async setModalDocumentObj(fileObj) {
      this.del_file_obj = await fileObj
    },
    setLoading(idx) {
      if (this.loading[idx] === undefined) {
        this.$set(this.loading, idx, true)
      } else {
        this.loading[idx] = !this.loading[idx]
      }
    },
    formatWeight(size) {
      let finalSize = size / 1000

      // mega
      if (finalSize > 1000) {
        finalSize /= 1000

        return `${finalSize.toFixed(2)} MB`
      }

      return `${finalSize.toFixed(0)} KB`
    },
    uploadEnabled(doc) {
      //return !(doc.document_setting_status.is_closed || doc.document_setting_status.is_completed || doc.document_setting_status.is_accepted)
      return true
    },
    downloadUrl(fileId) {
      return `${this.$apiUrl}ext/procedures/${this.$route.params.id}/documents/${this.$route.params.did}/files/${fileId}`
    },
    checkIfIsCreator() {
      return this.procedureData.users.some(user => user.id === this.loggedUser.id)
    },
  },
}
</script>

<style scoped>
  .container {
      max-width: 90%;
  }
</style>
