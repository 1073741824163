<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Elimina File</span>
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0">
            <v-col
              cols="12"
            >
              <p>L'eliminazione del file è permanente, accertarsi di voler continuare.</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ma-2"
            :disabled="loading"
            @click="closeModal"
          >
            Chiudi
          </v-btn>
          <v-spacer />
          <v-btn
            color="red"
            dark
            class="ma-2"
            :loading="loading"
            @click.prevent="destroyFile"
          >
            Elimina
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'DeleteFile',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    documentFileData: {
      type: Object,
      required: true,
    },
    isPluginVersion: {
      type: Boolean,
      default: false,
    },
    isGroupFileVersion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async destroyFile() {
      try {
        this.loading = true
        if (this.isGroupFileVersion) {
          await this.$api.destroyGroupFile(this.documentFileData.id, this.documentFileData)
        } else if (this.isPluginVersion) {
          await this.$api.destroyPluginFile(this.$route.params.id, this.$route.params.did, this.documentFileData.id, this.documentFileData)
        } else {
          await this.$api.destroyFile(this.$route.params.id, this.$route.params.did, this.documentFileData.id, this.documentFileData)
        }
        await this.$store.dispatch('set_alert', { msg: 'File Eliminato con successo', type: 'success' })
        this.$emit('reloadData')
        this.closeModal()
      } catch (e) {
        await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        this.loading = false
      }
    },
    closeModal() {
      this.$emit('closeModal')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
