import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isDropped)?_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"type":"article"}}):_c(VCard,{staticClass:"mt-3 d-flex flex-column",attrs:{"height":"100%","draggable":_vm.isDraggable},on:{"dragstart":_vm.onDragStart}},[(!_vm.noThumb && _vm.imageThumb)?_c(VImg,{staticClass:"mb-3",attrs:{"src":_vm.imageThumb}}):_vm._e(),_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"d-flex justify-start overflow-x-hidden"},[_c('p',{},[_vm._v(" "+_vm._s(_vm.fileObj.original_name)+" ")])]),_c(VCol,{staticClass:"d-flex justify-end"},[(_vm.canHandleFile && _vm.uploadEnabled)?_c('file-menu',{on:{"deleteFile":function($event){_vm.isShow.delete=true}}}):_vm._e()],1)],1),_c(VRow,[_c(VCol,{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(_vm.formatWeight(_vm.fileObj.size))+" ")])],1)],1),_c(VSpacer),_c(VCardActions,[_c(VBtn,{attrs:{"color":_vm.btnColor,"dark":_vm.btnDark,"block":"","small":"","rounded":"","loading":_vm.loading.download},on:{"click":function($event){_vm.downloadWithAxios(_vm.fileObj.original_name); _vm.loading.download=true;}}},[_vm._v(" Download ")])],1),_c('delete-file',{attrs:{"is-plugin-version":"","is-group-file-version":_vm.fileType === 'group',"is-show":_vm.isShow.delete,"document-file-data":_vm.fileObj},on:{"reloadData":_vm.reloadData,"closeModal":function($event){_vm.isShow.delete=false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }